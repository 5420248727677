// colors scheme for web
export const pendingColor = "#FCC43E";
export const ongoingColor = "#4AD6EF";
export const completeColor2 = "#5673ED";
export const completeColor = "#071285";

export const blue1 = "#4ad6efb7";
export const blue2 = "#4ad6ef7a";
export const blue3 = "#4ad6ef3f";
export const blue4 = "#4AD6EF";
export const blue5 = "#4ad6ef1f";
